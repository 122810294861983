import { v4 as uuidv4 } from "uuid";

export const KYC6Purpose = () => {
  return {
    name: "KYC - 6 - Purpose",
    container: {
      id: uuidv4(),
      dialogObjectId: "67372d3b5b307343c4f36c5b",
      lockToDialogObject: false,
      space: null,
      backgroundColor: "",
      margin: "20px 0",
      padding: null,
      borderWidth: null,
      borderColor: null,
      roundedCorners: false,
      static: false,
      name: "Purpose and nature",
      newPage: false,
      backgroundImage: null,
      backgroundSize: 0,
      backgroundRepeat: false,
      avoidBreakInside: false,
      rows: [
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-47-1",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "headlineNotification-1",
                  value: "The purpose and nature of the customer relationship",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: "#f5f5f5",
          margin: "5px 0",
          padding: "10px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-2",
                  value: "Is ACME the company's/society's main bank?",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-2",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "mainBankYes",
                  value: "mainBankYes",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Yes",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-2",
                  value: "mainBankYesNo",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "No",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-1-3",
                  value: "Primary reason for the business relationship",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-1-3",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-3d3e-1-3",
                  value: "payment",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Payments",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-1-3",
                  value: "investmentService",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Investment services",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-8b3f-1-2",
                  value: "loansCredits",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Loans/credits",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-d3dd-1-2",
                  value: "Radio button 919",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Other",
                  labelSetManually: false,
                  helpText: "other",
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: [
                    {
                      id: uuidv4(),
                      type: 2,
                      inputType: 0,
                      validateData: 0,
                      country: 0,
                      tableValueType: 0,
                      dynamicListValues: null,
                      video: null,
                      richTextValues: null,
                      bitmap: null,
                      layout: {
                        order: 2,
                        right: null
                      },
                      text: {
                        level: null,
                        alignment: "left",
                        fontFamily: "sans-serif",
                        size: 16,
                        bold: false,
                        italic: false,
                        underline: false,
                        color: "#000",
                        width: 0
                      },
                      labelStyle: {
                        level: null,
                        alignment: "left",
                        fontFamily: "sans-serif",
                        size: 16,
                        bold: false,
                        italic: false,
                        underline: false,
                        color: "#000",
                        width: 500
                      },
                      dependencies: {
                        value: "none",
                        type: 0
                      },
                      orderTable: null,
                      map: {
                        hideDropdown: false
                      },
                      customErrorMessage: null,
                      smartTable: null,
                      smartTableHeaders: [],
                      displayLabel: null,
                      valueLabel: null,
                      property: "inputTextLine-c7bb",
                      value: "New input text line",
                      placeholder: null,
                      borderWidth: "1px",
                      borderColor: "#000",
                      width: null,
                      height: null,
                      label: "",
                      labelSetManually: false,
                      helpText: null,
                      leadingText: null,
                      trailingText: null,
                      labelTop: true,
                      backgroundColor: "#fff",
                      roundedCorners: false,
                      defaultCheckedState: null,
                      isButton: null,
                      margin: null,
                      processStep: 0,
                      signatureProcess: null,
                      leadingIcon: null,
                      minValue: null,
                      maxValue: null,
                      minLength: null,
                      maxLength: null,
                      rows: null,
                      apiValidationId: null,
                      apiRetrieveId: null,
                      workflowRetrieveId: null,
                      workflowRetrieveLoadId: null,
                      options: null,
                      optionWithValueAlphabeticalOrder: false,
                      optionWithValues: null,
                      flowOptions: null,
                      preventDataInsert: false,
                      maxFileSizeInMb: null,
                      readOnly: false,
                      maxRows: null,
                      connectedParentElementId: "d3dd6c7f-9450-4a23-bd0c-f811c1e5f484",
                      connectedElements: null,
                      initialValue: null,
                      lockRows: false,
                      requiredField: null,
                      requiredSignatures: [],
                      hideLabel: false,
                      orderTableValue: null,
                      hasAutomaticTranslationOff: false,
                      connectedElement: null,
                      resourceId: null,
                      restrictedFileTypes: null,
                      matrix: null,
                      isDynamicElement: false
                    }
                  ],
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-1-1-4",
                  value: "The origin of the funds that will be deposited with the bank?",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-1-1-4",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-3d3e-1-1-4",
                  value: "profits",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Profits from operations",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-1-1-4",
                  value: "Income",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Income from operations",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-d3dd-1-1-2",
                  value: "other",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Other",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: [
                    {
                      id: uuidv4(),
                      type: 2,
                      inputType: 0,
                      validateData: 0,
                      country: 0,
                      tableValueType: 0,
                      dynamicListValues: null,
                      video: null,
                      richTextValues: null,
                      bitmap: null,
                      layout: {
                        order: 2,
                        right: null
                      },
                      text: {
                        level: null,
                        alignment: "left",
                        fontFamily: "sans-serif",
                        size: 16,
                        bold: false,
                        italic: false,
                        underline: false,
                        color: "#000",
                        width: 0
                      },
                      labelStyle: {
                        level: null,
                        alignment: "left",
                        fontFamily: "sans-serif",
                        size: 16,
                        bold: false,
                        italic: false,
                        underline: false,
                        color: "#000",
                        width: 500
                      },
                      dependencies: {
                        value: "none",
                        type: 0
                      },
                      orderTable: null,
                      map: {
                        hideDropdown: false
                      },
                      customErrorMessage: null,
                      smartTable: null,
                      smartTableHeaders: [],
                      displayLabel: null,
                      valueLabel: null,
                      property: "inputTextLine-a09b",
                      value: "New input text line",
                      placeholder: null,
                      borderWidth: "1px",
                      borderColor: "#000",
                      width: null,
                      height: null,
                      label: "",
                      labelSetManually: false,
                      helpText: null,
                      leadingText: null,
                      trailingText: null,
                      labelTop: true,
                      backgroundColor: "#fff",
                      roundedCorners: false,
                      defaultCheckedState: null,
                      isButton: null,
                      margin: null,
                      processStep: 0,
                      signatureProcess: null,
                      leadingIcon: null,
                      minValue: null,
                      maxValue: null,
                      minLength: null,
                      maxLength: null,
                      rows: null,
                      apiValidationId: null,
                      apiRetrieveId: null,
                      workflowRetrieveId: null,
                      workflowRetrieveLoadId: null,
                      options: null,
                      optionWithValueAlphabeticalOrder: false,
                      optionWithValues: null,
                      flowOptions: null,
                      preventDataInsert: false,
                      maxFileSizeInMb: null,
                      readOnly: false,
                      maxRows: null,
                      connectedParentElementId: "d3dd6c7f-9450-4a23-bd0c-f811c1e5f484",
                      connectedElements: null,
                      initialValue: null,
                      lockRows: false,
                      requiredField: null,
                      requiredSignatures: [],
                      hideLabel: false,
                      orderTableValue: null,
                      hasAutomaticTranslationOff: false,
                      connectedElement: null,
                      resourceId: null,
                      restrictedFileTypes: null,
                      matrix: null,
                      isDynamicElement: false
                    }
                  ],
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-1-1-3",
                  value: "Amount of domestic payments per month",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-1-1-3",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-3d3e-1-1-3",
                  value: ">100,000 SEK",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "0 – 100,000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-1-1-3",
                  value: "100.001500.000 SEK",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "100,001 – 500,000 SEK",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-8b3f-1-1-2",
                  value: "Radio button 743",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "500.001 – 2.000.000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-8b3f-1-1-1-3",
                  value: "Radio button 743",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "> 2.000.000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-1-1-1-2",
                  value: "Amount of domestic payments per month",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-1-1-1-2",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-3d3e-1-1-1-2",
                  value: "Radio button 576",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "0 – 100.000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-1-1-1-2",
                  value: "Radio button 59",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "100.001 – 500.000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-8b3f-1-1-1-2",
                  value: "Radio button 743",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "500.001 – 2.000.000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-8b3f-1-1-1-1-1",
                  value: "Radio button 743",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "> 2.000.000 SEK",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-1-2",
                  value: "Will the accounts be used for regular foreign transactions? (more than 5 per year)",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-1-2",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-3d3e-1-2",
                  value: "foreignTransactionYes ",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Yes",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-1-2",
                  value: "foreignTransactionNo",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "No",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2-1-1-2",
                  value: "Will the bank's products and/or services be used to trade with cryptocurrencies?",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-6609-1-1-2",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 10,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-3d3e-1-1-2",
                  value: "cryptoTradeYes",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Yes",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-0592-1-1-2",
                  value: "cryptoTradeNo",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "No",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        }
      ],
      showLogicControl: [],
      hasAutomaticTranslationOff: false,
      resourceId: null,
      readOnly: null
    }
  }
}