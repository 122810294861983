export const SidebarButton = ({
  onClick,
  text,
  color,
  addSeparator,
  disabled = false,
  icon = null,
  innerRef = null,
  draggableProps = null,
  draggableHandleProps = null,
  style = null,
  readOnly = false,
}) => {

  const colorStyle = color ? `bg-${color}-200 hover:bg-${color}-300` : 'bg-white hover:bg-gray-300';
  
  return (
      disabled ? 
      <></> 
      :
      <>
        <div 
          ref={innerRef}
          {...draggableProps}
          {...draggableHandleProps}
          style={style}
          className={`flex items-center rounded-sm p-2 text-sm cursor-pointer border border-gray-300 ${colorStyle} ${readOnly ? 'cursor-not-allowed opacity-80' : ''}`} 
          onClick={!readOnly ? onClick : null}
        >
          { icon &&
            <div className='w-6 mr-3'>
              {icon}
            </div>
          }
          <div className="flex">
            { text }
          </div>
        </div>
        {
          addSeparator &&
          <div className="col-span-2 mt-4"></div>
        }
      </>
  );
}