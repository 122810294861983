import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import {
  deleteDialogValuesFile,
  saveDialogValuesSmartTable,
  uploadDialogValuesFile,
  updateDialogValues,
  resetDialogValues,
  generatePdf,
  deleteValuesByElementIdentifiers,
  generatePdfForArchivedDialogValues,
  loadForm,
  getDialogValuesSignUrl,
  getStepSignUrl,
} from '../../api/public/dialogValues';
import { FormContext } from '../../contexts';
import * as Structures from "../../structures";
import { useDebounce, useDebounceCallback } from '@react-hook/debounce'
import moment from 'moment';
import Enumerable from 'linq'
import { valuesKeyValuePairToModel, valuesModelToKeyValuePair } from '../../utils/dialogValues';
import { findFieldsWithNoValue, requiredFieldsObjectsToArray } from '../../utils/elements';
import { useNavigate } from "react-router-dom";
import './styles.scss';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { dialogValuesStepStatus, elementTypes, processTypes, processValues, validateDataTypes } from '../../utils/constants';
import GeneratePdfDialog from '../../components/GeneratePdfDialog';
import SendingPdfToViewPointDialog from './sendingPdfToViewPointDialog';
import { Select } from '../../components/Form/Select';
import { useTokenAuthentication } from './useTokenAuthentication';
import { isValidInputTextLine } from '../../utils/validators';
import { setDialogDefinition as setDialogDefinitionReducer } from "../../slices/dialogDefinitionSlice"
import { useDispatch } from 'react-redux';
import { useTranslations } from './useTranslations';
import { useModalHelper } from '../../hooks/useModalHelper';
import { PartiesToSign } from './partiesToSign';
import PowerOfAttorney from './powerOfAttorney';
import { getElementKey, getErrorKey } from '../../utils/features';
import { getArchiveById } from '../../api/archive';
import { useQueryFilter } from '../../hooks/useQueryFilter';
import SignNowModal from './signNowModal';
import React from 'react';
import { useAutomaticSignUrlCheck } from './useAutomaticSignUrlCheck';
import SigningReadyModal from './signingReadyModal';
import useDigitalSigningHelper from './useDigitalSigningHelper';
import { documentSigningModes } from '../Admin/DialogProcess/normalProcessDigitalSigning';
import PreparingSignDocumentModal from './preparingSignDocumentModal';
import { useDefinitionManager } from '../Admin/DialogDesign/useDefinitionManager';
import { useDynamicRows } from '../../elements/useDynamicRows';
import Loader from '../../components/Loader';
import _, { set } from 'lodash';
import { getAllElements } from '../../utils/dialogDefinitions';
import { getContactListContacts } from '../../api/public/contactList';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { getInformationWorkflow } from '../../api/apiDefinition';
import { FileText } from 'lucide-react';

export default function EditForm({ loadFromArchive }) {
  const dispatch = useDispatch()
  const { dialogKey, valuesKey, currentStepKey, archiveId, companyId } = useParams();
  const archiveIdRef = useRef(archiveId)
  const [formTimeoutOver, setFormTimeoutOver] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showGeneratePdf, setShowGeneratePdf] = useState(false);
  const [dialogValues, setDialogValues] = useState();
  const [showSendingPdfToViewPoint, setShowSendingPdfToViewPoint] = useState(false);
  const [dialogDefinition, setDialogDefinition] = useState();
  const [dialogFormValues, setDialogFormValues] = useState();
  const [availableSignAuthMethods, setAvailableSignAuthMethods] = useState([]);
  const [processStep, setProcessStep] = useState();
  const [isFormEnabled, setIsFormEnabled] = useState();
  const [autoSave, setAutoSave] = useDebounce(null, 250)
  const [saveMessage, setSaveMessage] = useState();
  const [requiredFields, setRequiredFields] = useState([])
  const [valuesRedirectUrl, setValuesRedirectUrl] = useState();
  const [step, setStep] = useState();
  const [currentStepElements, setCurrentStepElements] = useState();
  const [formIsAborted, setFormIsAborted] = useState(false);
  const errorHandler = useErrorHandler();
  const formRef = useRef();
  const tokenAuthentication = useTokenAuthentication();
  const [reusableProperties, setReusableProperties] = useState({});
  const navigate = useNavigate();
  const [textLineInputs, setTextLineInputs] = useState([]);
  const [requiredRadioButtonsRows, setRequiredRadioButtonsRows] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showAttorney, setShowAttorney] = useState(false);
  const [atternyData, setAtternyData] = useState(false);
  const formErrors = [];
  const [errorsWithDetail, setErrorsWithDetail] = useState([])
  const [firstRender, setFirstRender] = useState(true) // prevent the autosave on first render
  // const [partiesToSign, setPartiesToSign] = useState([]);
  const partiesToSignModalHelper = useModalHelper();
  const signNowModalHelper = useModalHelper();
  const signingReadyModalHelper = useModalHelper();
  const preparingSignDocumentModalHelper = useModalHelper();
  const [isSigningNow, setIsSigningNow] = useState(false)
  const [signUrl, setSignUrl] = useState();
  const definitionManager = useDefinitionManager()
  const dynamicRows = useDynamicRows();
  const [digitalSigningProviderDetails, setDigitalSigningProviderDetails] = useState();
  const existRadioGroup = definitionManager.elementsOfTypeFromDialogDefinition(dialogDefinition, [elementTypes.radioButtonGroup])
  const [hiddenContainers, setHiddenContainers] = useState([]);
  const loadWorkflowAction = useToastAction()

  const automaticSignUrlCheck = useAutomaticSignUrlCheck(async () => {
    if (dialogDefinition.process === processTypes.normal) {
      return await getDialogValuesSignUrl(valuesKey);
    }
    return await getStepSignUrl(valuesKey, currentStepKey);
  }, 'signUrl', (signUrl) => handleSignUrlCheckComplete(signUrl));

  const [signingModalRendered, setSigningModalRendered] = useState(false);
  const { isSigned, isPendingSignature, isStepPendingSignature, isNormalPendingSignature, documentSignatories, canSignNow, canDefinePartiesToSign, shouldOpenDigitalSigning } = useDigitalSigningHelper(dialogDefinition, dialogValues, step);

  const digitalSigningValues = {
    hasDigitalSigning: dialogDefinition?.hasDigitalSigning,

    //flags for process signing
    lastPartyToSignFromSmartform: dialogDefinition?.lastPartyToSignFromSmartform,
    lastPartyDefineSignatories: dialogDefinition?.lastPartyDefineSignatories,
    notificationLinkUponCompletion: dialogDefinition?.notificationLinkUponCompletion,

    //flags for normal process signing 
    digitalSigningAllowDirectSigning: dialogDefinition?.digitalSigningAllowDirectSigning,

    //Sign now modal texts
    signNowMessage: dialogDefinition?.digitalSigningSignNowMessage,
    signNowDescription: dialogDefinition?.digitalSigningSignNowDescription,
    signNowBtn: dialogDefinition?.digitalSigningSignNowBtnText,
    signNowCancelBtn: dialogDefinition?.digitalSigningCancelBtnText,
    askOtherBtn: dialogDefinition?.digitalSigningAskOtherBtnText,

    //Parties to sign modal texts
    message: dialogDefinition?.digitalSigningMessage,
    description: dialogDefinition?.digitalSigningDescription,
    firstColumn: dialogDefinition?.digitalSigningFirstColumn,
    secondColumn: dialogDefinition?.digitalSigningSecondColumn,
    error: dialogDefinition?.digitalSigningError,
    add: dialogDefinition?.digitalSigningAdd,
    cancel: dialogDefinition?.digitalSigningCancel,
    submit: dialogDefinition?.digitalSigningSubmit,

  }

  const pdfQuery = useQueryFilter("pdf")
  const languageCodeQuery = useQueryFilter("languageCode")
  const jwtQuery = useQueryFilter("jwt")
  const envQuery = useQueryFilter("environment");

  const isPdf = pdfQuery.value === "true"
  const translations = useTranslations(dialogKey, languageCodeQuery.value)

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (!signUrl) return;

    handleSignUrlChange();
  }, [signUrl])

  useEffect(() => {
    if (isLoading) return;
    handleDialogLoaded();
    if(dialogDefinition)
      getToolbarTextColor()
  }, [isLoading])

  useEffect(() => {
    if (isLoading) return;

    if (automaticSignUrlCheck.isChecking) {
      preparingSignDocumentModalHelper.open();
    } else {
      preparingSignDocumentModalHelper.close();
    }

  }, [automaticSignUrlCheck.isChecking])


  useEffect(() => {
    if (!firstRender) {
      onAutoSave()
    }
    else {
      setFirstRender(false)
    }
  }, [autoSave])

  useEffect(() => {
    if (translations.selectedLanguageOption && translations.definitionLanguageOptions?.length > 1) {
      languageCodeQuery.set(translations.selectedLanguageOption.code, false)
    }
  }, [translations.selectedLanguageOption])

  const handleDialogLoaded = () => {
    isFirstInitialLoadOfForm() ? dialogDefinition.workflowRetrieveLoadId && loadWorkflowData() : handleSignatures();
  }

  const loadWorkflowData = () => {
    loadWorkflowAction.executeAsync(async () => {
      const response = await getInformationWorkflow(dialogKey, valuesKey, dialogDefinition.workflowRetrieveLoadId, [])
      response.forEach(element => {
        if(!element?.preventDataInsert)
          updateByPropertyNameValue(element.property, element.value)
        });
    }, "Failed to load Workflow data")
  };

  const handleSignUrlCheckComplete = (checkSignUrl) => {
    setSignUrl(checkSignUrl);
    if (checkSignUrl) {
      window.open(checkSignUrl, '_blank', 'noreferrer')
    }
  }

  const handleSignatures = () => {
    const processPendingSignature = isStepPendingSignature(step?.signUrl) && dialogDefinition.process === processTypes.multiStep
    if (processPendingSignature) {
      signingReadyModalHelper.open();
      return;
    }

    const normalPendingSignature = !isPdf && dialogDefinition.process === processTypes.normal && isNormalPendingSignature(dialogValues?.signUrl)

    if (normalPendingSignature) {
      setSignUrl(dialogValues?.signUrl);
      signingReadyModalHelper.open();
    }
  }

  const handleSignUrlChange = () => {
    if (isStepPendingSignature(signUrl)) {
      signingReadyModalHelper.open();
    }
  }

  const load = async () => {
    let definition,
      dialogValues,
      step,
      digitalSigningProviderDetails;

    if (loadFromArchive) {
      const response = await getArchiveById(archiveId, companyId);
      definition = response.dialogDefinition;
      dialogValues = response.dialogValues;
    }
    else {
      const loadResult = await loadForm({
        dialogDefinitionId: dialogKey,
        dialogValuesId: valuesKey,
        stepId: currentStepKey,
        dialogValuesJwt: tokenAuthentication.getJwtForDialogValues(valuesKey),
        stepJwt: tokenAuthentication.getJwtForDialogValuesStep(valuesKey, currentStepKey),
        pdfJwt: jwtQuery.value,
      })

      // redirect to form authorize page
      if (loadResult.authenticationRequired) {
        if (loadResult.contactList) {
          tokenAuthentication.handlePhoneListProcessUnauthorized(dialogKey, valuesKey)
        }
        else {
          tokenAuthentication.handleUnauthorized();
        }
        return
      }

      // set jwt headers for subsequent api calls
      if (tokenAuthentication.getJwtForDialogValues(valuesKey)) {
        tokenAuthentication.setHeaderForDialogValuesAuth(valuesKey)
      }
      else if (tokenAuthentication.getJwtForDialogValuesStep(valuesKey, currentStepKey)) {
        tokenAuthentication.setHeaderForStepAuth(valuesKey, currentStepKey)
      }

      // initialize main records
      definition = loadResult.dialogDefinition
      dialogValues = loadResult.dialogValues
      step = loadResult.step

      digitalSigningProviderDetails = loadResult.digitalSigningProviderDetails;
      if (step) {
        setStep(step)
      }

      if (digitalSigningProviderDetails) {
        setDigitalSigningProviderDetails(digitalSigningProviderDetails)
      }

      getAvailableSignAuthMethods(digitalSigningProviderDetails?.availableSignAuthMethods);
      dispatch(setDialogDefinitionReducer({
        dialogDefinition: definition
      }))
    }

    // the rest of the form initialization
    if (dialogValues.updatedDate) {
      const message = dialogValues.isComplete ? "Completed" : "Last updated";
      setSaveMessage(`${message} ${moment(dialogValues.updatedDate).fromNow()}`)
    }

    let formatedContactListToReusableProperties = {};
    if (!dialogDefinition?.contactListId) {
      const contactListContacts = await getContactListContacts(dialogDefinition?.contactListId);
      formatedContactListToReusableProperties = formatContactListContactToReusableProperties(contactListContacts);
    }

    setProcessStep(definition.process === processTypes.multiStep ? currentStepKey : null);
    if (step) {
      setIsFormEnabled(step.dialogValuesStepStatus === dialogValuesStepStatus.inProgress)
    }
    else {
      setIsFormEnabled(!dialogValues.isComplete)
    }
    setFormIsAborted(dialogValues.isAborted)
    setValuesRedirectUrl(dialogValues.externalRedirectUrl);
    setDialogFormValues(valuesModelToKeyValuePair(dialogValues));
    setDialogDefinition(definition);
    setDialogValues(dialogValues);

    setReusableProperties({ ...reusableProperties, ...dialogValues.company, ...formatedContactListToReusableProperties })
    setAtternyData(dialogValues?.atternoy)

    setRequiredElementsWithoutHiddenContainers(definition, hiddenContainers, step)
    definition?.name && (window.document.title = definition?.name);

    if (loadFromArchive) {
      setIsFormEnabled(false)
    }
    setIsLoading(false);
  }

  const formatContactListContactToReusableProperties = (contactListContacts) => {
    let formatedContactList = {};
    contactListContacts.forEach((contact, cIndex) => {
      const keys = Object.keys(contact);
      keys.forEach(key => {
        formatedContactList[`phonelist_${cIndex}_${key}`] = contact[key];
      })
    })

    return formatedContactList
  }

  const isFirstInitialLoadOfForm = () => {
    const createdTime = new Date(dialogValues.createdDate).toISOString().slice(0, 19) + "Z"
    const updatedTime = new Date(dialogValues.updatedDate).toISOString().slice(0, 19) + "Z"
    return createdTime === updatedTime
  }

  const setRequiredElementsWithoutHiddenContainers = (dialogDefinition, hiddenContainers, step) => {
    const hiddenContainersIds = hiddenContainers.map(c => c.id);
    let elements = [];
    let containers = Enumerable.from(dialogDefinition.containers);
    let requiredRadioGroup = [];

    if (step) {
      containers = containers.where(c => step.containerDefinitionIds?.includes(c.id));
    }

    elements = containers
      .where(c => !hiddenContainersIds.includes(c.id))
      .selectMany(c => {
        const rowsWithRequiredRadioGroup = c.rows.filter(r => r.requiredRadioGroup);
        requiredRadioGroup.push(...rowsWithRequiredRadioGroup);

        return c.rows.flatMap(r => r.columns.flatMap(col => col.elements));
      })
      .toArray();

    setCurrentStepElements(elements)
    setStep(step);
    setRequiredRadioButtonsRows(requiredRadioGroup);
    setTextLineInputs(elements.filter(e => e.type === elementTypes.inputTextLine))

    setRequiredFields(elements.filter(e => e.requiredField))
  }


  const handleHiddenContainersChange = (hiddenContainers) => {
    setHiddenContainers(hiddenContainers);
    setRequiredElementsWithoutHiddenContainers(dialogDefinition, hiddenContainers, step)
  }

  const validateRadioButtonsRequireds = async (dialogFormValues) => {
    let validRadioButtons = false;
    const valuesArray = Object.values(dialogFormValues);

    requiredRadioButtonsRows.forEach(cont => {
      const groupValue = valuesArray.findIndex(x => x.group === cont.id && x.value)
      const errorObj = {
        id: cont.id,
        property: cont.name,
        field: cont.name,
        validateData: "",
        customErrorMessage: null,
        required: true,
        isContainer: true
      }

      if (groupValue === -1) {
        addOrRemoveErrorInput(errorObj, "add")
        validRadioButtons = true;
      }
    })

    return validRadioButtons;
  }

  const invalidTextLineInputs = async (dialogFormValues) => {
    const data = valuesKeyValuePairToModel(dialogFormValues)
    let invalidInputs = []

    // TODO dynamic rows for textLineInputs??
    for (const textLineInput of textLineInputs) {
      const dataElement = data.find(d => d.getKey() === getElementKey(textLineInput));
      if (!dataElement) {
        continue
      }

      if (textLineInput.requiredField) {
        const isValid = await isValidInputTextLine(textLineInput, dataElement.value)
        if (!isValid) {
          invalidInputs.push(textLineInput);
        }
      }

      if (textLineInput.validateData !== validateDataTypes.text) {
        const isValid = await isValidInputTextLine(textLineInput, dataElement.value)
        if (!isValid) {
          const errorObj = {
            id: textLineInput.id,
            property: textLineInput.property,
            field: textLineInput.label,
            validateData: textLineInput.validateData,
            customErrorMessage: textLineInput.customErrorMessage,

            // only pass in true if there is also no value, because for some reason the thing that renders error messages doesn't
            // take into account the value the input has. If required is true, it will blindly just say the input is required
            // even if it has a value
            required: textLineInput.requiredField && !dataElement.value
          }
          invalidInputs.push(textLineInput);
          addOrRemoveErrorInput(errorObj, "add")
        }
      }
    }

    return invalidInputs.length > 0;
  }

  const missingRequiredFields = (dialogFormValues) => {
    const data = valuesKeyValuePairToModel(dialogFormValues, true)

    const obj = findFieldsWithNoValue(dynamicRows.addRequiredElementsFromDynamicRows(requiredFields, data), data)

    const allRequiredField = requiredFieldsObjectsToArray(obj)
    allRequiredField.map(field => {

      const errorObj = {
        id: field.id,
        property: field.property,
        field: field.label,
        validateData: field.validateData,
        customErrorMessage: field.customErrorMessage,
        required: field.requiredField
      }

      return addOrRemoveErrorInput(errorObj)
    })

    return allRequiredField?.length > 0
  }

  const getAvailableSignAuthMethods = (providerMethodsList) => {
    const optionsForSigning = [
      { value: 'standard', name: 'Standard' },
      { value: 'no_bankid', name: 'Norwegian BankId', defaultForCountry: 'no' },
      { value: 'se_bankid', name: 'Swedish BankId', defaultForCountry: 'se' },
    ];
    let result = [];
    if (providerMethodsList) {

      Object.keys(providerMethodsList).forEach((methodKey) => {
        if (providerMethodsList[methodKey]) {
          const newOptionToAdd = optionsForSigning.find(option => option.value === methodKey);
          if (newOptionToAdd)
            result.push(newOptionToAdd);
        }

      })
      setAvailableSignAuthMethods(result);
    }
  };


  const handleSendToSigning = async (partiesToSign, signNow = false) => {
    await onSave(null, signNow, partiesToSign);
  }

  const handleSignNow = async (event) => {
    await onSave(event, true)
  }
  const startCheckForSignUrlReady = () => {
    automaticSignUrlCheck.startChecking();
  }

  const scrollTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  const removeHiddenFields = (fields) => {
    const clonedFields = fields;

    for (const field of Object.values(fields)) {
      const container = definitionManager.findContainerFromElement(dialogDefinition, field.elementId);
      const isHiddenContainer = hiddenContainers.find(c => c.id === container?.id);

      if (isHiddenContainer) {
        delete clonedFields[field.property]
        addOrRemoveErrorInput({ id: field.id, property: field.property }, "remove")
      }
    }

    return clonedFields
  }

  const onSave = async (event, isSigningNow = false, partiesToSign = []) => {
    event?.preventDefault();
    event?.stopPropagation();

    const values = { ...dialogFormValues }
    const valuesToValidate = removeHiddenFields(values)

    const deletedElementPropertyNames = dynamicRows.onFormSubmit(valuesToValidate, requiredFields)
    const missRequiredFields = missingRequiredFields(valuesToValidate)
    const invalidInputs = await invalidTextLineInputs(valuesToValidate)
    const invalidRequireRadioButtons = await validateRadioButtonsRequireds(valuesToValidate);

    const hiddenContainersIds = hiddenContainers.map(c => c.id);
    setDialogFormValues({ ...values })
    let formSubmitted = false;
    if (isFormEnabled) {
      if (missRequiredFields || invalidRequireRadioButtons) {
        scrollTop()
        toast.error("Required fields are missing values", {
          id: "toast-validation"
        })
      }
      else if (invalidInputs) {
        scrollTop()
        toast.error("Text field has a invalid format", {
          id: "toast-validation"
        })
      }
      else {
        if ((shouldOpenDigitalSigning) && !isSigningNow && !partiesToSign[0]) {
          partiesToSignModalHelper.open()
        }
        else {
          try {
            setSaveMessage("...Saving")
            setIsFormSubmitting(true);
            const updateResult = await updateDialogValues(valuesKey, valuesKeyValuePairToModel(values), true, tokenAuthentication.handleUnauthorized, partiesToSign, deletedElementPropertyNames, isSigningNow, hiddenContainersIds);
            setSaveMessage(`Completed ${moment(updateResult.updatedDate).fromNow()}`)
            setIsFormEnabled(false);
            formSubmitted = true;
            // Remove any errors that might exist for deleted elements
            setErrorsWithDetail(errorsWithDetail.filter(e => !deletedElementPropertyNames.includes(e.property) && Object.values(valuesToValidate).find(v => v.elementId === e.id)))


            toast.success("Saved");
            if (isSigningNow) {
              startCheckForSignUrlReady();
            }
            partiesToSignModalHelper.close();
            signNowModalHelper.close();

            if (dialogDefinition.generatePdfOnSubmit && (!missRequiredFields && !invalidRequireRadioButtons)) {
              await downloadPdf()
            }
          }
          catch (err) {
            setSaveMessage(null)
            errorHandler.handleApiError(err, errorHandler.errorHasValidationErrors(err) ? "Required fields are missing values" : "Failed to save values");
          }
          finally {
            setIsFormSubmitting(false);
          }

          if (formSubmitted) {
            if (valuesRedirectUrl) {
              const redirect = valuesRedirectUrl.replace("[valuesKey]", valuesKey)
              window.location.href = redirect;
            }

            if (dialogDefinition.externalRedirectUrl && !valuesRedirectUrl) {
              const redirect = dialogDefinition.externalRedirectUrl.replace("[valuesKey]", valuesKey)
              window.location.href = redirect;
            }
          }
        }
      }
    }
  }

  const onClickPreviewButton = async () => {
    await downloadPdf()
  }

  const downloadPdf = async () => {
    setShowGeneratePdf(true);
    if (archiveIdRef.current) {
      await generatePdfForArchivedDialogValues(dialogValues.id, archiveIdRef.current, languageCodeQuery.value)
    }
    else {
      await generatePdf(valuesKey, currentStepKey, languageCodeQuery.value);
    }
    setShowGeneratePdf(false);
  }

  const onClickAttorneyButton = () => {
    setShowAttorney(true);
  }

  const onAutoSave = useDebounceCallback(() => {

    const saving = async () => {
      if (dialogFormValues && isFormEnabled) {
        try {
          setSaveMessage("...Saving")
          await updateDialogValues(valuesKey, valuesKeyValuePairToModel(dialogFormValues), false, tokenAuthentication.handleUnauthorized);
          setSaveMessage(`Saved`)
        }
        catch (err) {
          setSaveMessage(null)
          errorHandler.handleApiError(err, "Failed to save values");
        }
      }
    }

    saving();
  }, 2000)

  const onResetForm = async () => {
    if (isFormEnabled) {
      try {
        setSaveMessage("...Saving")
        await resetDialogValues(valuesKey, tokenAuthentication.handleUnauthorized)
        setDialogFormValues([])
        setSaveMessage(`Saved`)
        window.location.reload();
      }
      catch (err) {
        setSaveMessage(null)
        errorHandler.handleApiError(err, "Failed to reset form");
      }
    }
  }

  // TODO since switching to using element name as the key, I think we updateKeyValue can be called instead of this
  const updateByPropertyNameValue = (propertyName, value) => {
    const values = dialogFormValues;
    values[propertyName] = {
      ...values[propertyName],
      value,
    };
    setDialogFormValues({ ...values });
    setAutoSave(new Date()); // set an arbitrary changed value to trigger debounce effect
  }

  const onUpdateSignatureName = (key, value) => {
    const values = dialogFormValues;

    values[key] = {
      ...values[key],
      signatureName: value,
    };


    setDialogFormValues({ ...values });
    setAutoSave(new Date()); // set an arbitrary changed value to trigger debounce effect
  }

  const onUpdateFormValueProperty = (key, propertyName, value) => {
    const values = dialogFormValues;

    values[key] = {
      ...values[key],
      [propertyName]: value,
    };

    setDialogFormValues({ ...values });
    setAutoSave(new Date()); // set an arbitrary changed value to trigger debounce effect
  }

  const updateKeyValue = (key, value, group, setPrevState = false) => {
    const values = _.cloneDeep(dialogFormValues);

    if (group) {
      // TODO BN what to do with group & dynamic row???

      // Find all values in the same group, and null them out
      // The current update will then set the value for the element this event was fired for.
      const elementIdsInGroup = Object.keys(values)
        .filter(key => dialogFormValues[key].group === group)
      elementIdsInGroup.forEach(elementId => {
        values[elementId] = {
          ...values[elementId],
          value: null,
        }
      })
    }

    values[group] = {
      ...values[group],
      value,
      group,
    };

    values[key] = {
      ...values[key],
      value,
      group,
    };

    // TODO BN Is there a way to debounce this bit to improve performance????
    dynamicRows.addFormValuesForDynamicRowIfNeeded(values, key, value, definitionManager, dialogDefinition)
    const elementsToDelete = dynamicRows.removeLastDynamicRowIfNeeded(values, key, value, definitionManager, dialogDefinition)
    if (elementsToDelete?.length > 0) {
      deleteValuesByElementIdentifiers(valuesKey, elementsToDelete, tokenAuthentication.handleUnauthorized)
        .then(() => {
          setErrorsWithDetail(errorsWithDetail.filter(e => !elementsToDelete.includes(e.property)))
        })
    }

    let valuesWithOnlyLast = {};

    if (setPrevState)
      valuesWithOnlyLast = { [key]: values[key] }

    setDialogFormValues((prev) => setPrevState ? ({ ...prev, ...valuesWithOnlyLast }) : { ...values });
    setAutoSave(new Date()); // set an arbitrary changed value to trigger debounce effect
  }

  const updateFileKeyValue = async (key, file) => {
    if (isFormEnabled) {
      try {
        setSaveMessage("...Saving")
        const result = await uploadDialogValuesFile(valuesKey, key, file, tokenAuthentication.handleUnauthorized);
        if (!dialogFormValues[key]) {
          dialogFormValues[key] = {}
        }
        if (!dialogFormValues[key].files) {
          dialogFormValues[key].files = []
        }
        dialogFormValues[key].files.push(result)
        setDialogFormValues({ ...dialogFormValues });
        setSaveMessage(`Saved`)
      }
      catch (err) {
        setSaveMessage(null)
        errorHandler.handleApiError(err, "Failed to upload file");
      }
    }
  }

  const deleteFile = async (key, id) => {
    if (isFormEnabled) {
      try {
        setSaveMessage("...Saving")
        await deleteDialogValuesFile(valuesKey, key, id, tokenAuthentication.handleUnauthorized);
        dialogFormValues[key].files = dialogFormValues[key].files.filter(f => f.id !== id);
        setDialogFormValues({ ...dialogFormValues });
        setSaveMessage(`Saved`)
      }
      catch (err) {
        setSaveMessage(null)
        errorHandler.handleApiError(err, "Failed to delete file");
      }

    }
  }

  const onUpdateSmartTable = async (key, values) => {
    if (isFormEnabled) {
      try {
        setSaveMessage("...Saving")
        const result = await saveDialogValuesSmartTable(valuesKey, key, values, tokenAuthentication.handleUnauthorized);
        if (!dialogFormValues[key]) {
          dialogFormValues[key] = {}
        }
        if (!dialogFormValues[key].smartTableValues) {
          dialogFormValues[key].smartTableValues = {}
        }
        dialogFormValues[key].smartTableValues = JSON.stringify(result);
        setDialogFormValues({ ...dialogFormValues });
        setSaveMessage(`Saved`)

        handleReusablePropertyChanged(key, JSON.stringify(result))
      }
      catch (err) {
        setSaveMessage(null)
        errorHandler.handleApiError(err, "Failed to save values");
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setFormTimeoutOver(true);
    }, 1500);
  }, [])

  const handleReusablePropertyChanged = (key, value) => {
    setReusableProperties(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const addOrRemoveErrorInput = ({ id, property, field, validateData, required, customErrorMessage }, action = "add") => {
    const errorObj = { id, property, field, validateData, required, customErrorMessage }
    const errorIndex = formErrors.findIndex(error => getErrorKey(error) === getErrorKey(errorObj))
    const errorIndexExist = errorIndex !== -1;

    const errorDetailIndex = errorsWithDetail.findIndex(error => getErrorKey(error) === getErrorKey(errorObj));
    const errorIndexDetailExist = errorDetailIndex !== -1;

    if (action === "add") {
      !errorIndexExist && formErrors.push(errorObj)
      if (!errorIndexDetailExist) {
        setErrorsWithDetail(errors => [...errors, errorObj])
      }
      else {
        // update the error message if it already exists. eg. if initial error message is that a field is
        // required, user sets a value, but the value fails the specific input type validation such as email
        setErrorsWithDetail(errorsWithDetail.map((e, index) => {
          return errorDetailIndex === index ? errorObj : e
        }))
      }
    } else {
      errorIndexExist && formErrors.splice(errorIndex, 1)
      if (errorIndexDetailExist) {
        const newErrorsList = [...errorsWithDetail]
        newErrorsList.splice(errorDetailIndex, 1)
        setErrorsWithDetail(newErrorsList)
      }
    }
  }

  const [toolBarTextColor, setToolBarTextColor] = useState('text-black');

  const getToolbarTextColor = () => {
    if(!dialogDefinition?.toolbarColor) return
    let hex = dialogDefinition?.toolbarColor?.replace('#', '');

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    let brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return setToolBarTextColor(brightness > 128 ? 'text-black' : 'text-white');
  }

  return (
    <>
      {
        (isLoading || translations.isLoading || !formTimeoutOver) ?
          <div className='w-full h-full flex flex-col justify-center items-center'>
            <Loader height={60} width={60} />
          </div>
          // <div className="flex justify-between px-5 py-1 print:p-0 mt-10">
          //   <div className="flex items-center m-auto w-full max-w-3xl">
          //   <div role="status" class="w-full p-4 rounded animate-pulse md:p-6 dark:border-gray-700">
          //       <div class="flex items-center justify-center h-24 mb-6 bg-gray-200 rounded dark:bg-gray-700 mb-10"></div>
          //       <div class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-6"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-10"></div>
          //       <div class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-6"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-10"></div>
          //       <div class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-6"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-10"></div>
          //       <div class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-6"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
          //       <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-6"></div>

          //       <span class="sr-only">Loading...</span>
          //   </div>
          // </div>
          // </div>
          :
          <FormContext.Provider
            value={{
              reusablePropertyChanged: handleReusablePropertyChanged,
              reusableProperties: reusableProperties,
              inputValues: dialogFormValues,
              dialogValues: dialogValues,
              processStep: processStep,
              isFormEnabled,
              isAborted: formIsAborted,
              dialogDefinition: dialogDefinition,
              dialogDefinitionId: dialogKey,
              stepContainerDefinitionIds: step?.containerDefinitionIds,
              currentStepElements: currentStepElements,
              isFormSubmitting: isFormSubmitting,
              elements: getAllElements(dialogDefinition),
              step: step,
              isPendingSignature: isPendingSignature,
              formIsSigned: isSigned,
              documentSignatories: documentSignatories,
              digitalSigningProviderDetails: digitalSigningProviderDetails,
              digitalSigningValues: {
                ...digitalSigningValues
              },
              processValues: processValues,
              updateValue: (key, value, group = null, setPrevState = false) => {
                updateKeyValue(key, value, group, setPrevState);
              },
              updateByPropertyNameValue: (propertyName, value) => {
                updateByPropertyNameValue(propertyName, value);
              },
              updateFormValueProperty: (key, propertyName, value) => {
                onUpdateFormValueProperty(key, propertyName, value)
              },
              uploadFile: (key, file) => {
                updateFileKeyValue(key, file);
              },
              deleteFile: (evt, key, id) => {
                evt.preventDefault();
                deleteFile(key, id);
              },
              updateSmartTable: (key, values) => {
                onUpdateSmartTable(key, values);
              },
              updateSignatureName: (key, value) => {
                onUpdateSignatureName(key, value)
              },
              resetForm: onResetForm,
              formRef: formRef,
              translateTerm: (element, fallbackText, propertyName) => {
                return translations.translateTerm(element, fallbackText, propertyName)
              },
              translateOption: (element, optionId, fallbackText, propertyName) => {
                return translations.translateOption(element, optionId, fallbackText, propertyName)
              },
              translateContainer: (element, fallbackText, propertyName) => {
                return translations.translateContainer(element, fallbackText, propertyName)
              },
              translateDigitalSigning: (fallbackText) => {
                return translations.translateDigitalSigning(fallbackText);
              },
              translateProcess: (fallbackText) => {
                return translations.translateProcess(fallbackText);
              },
              translateHelpText: (element, fallbackText) => {
                return translations.translateHelpText(element, fallbackText)
              },
              translateCustomErrorMessage: (element, isRequired) => {
                return translations.translateCustomErrorMessage(element, isRequired)
              },
              translateRow: (element, rowIndex, columnIndex, fallbackText) => {
                return translations.translateRow(element, rowIndex, columnIndex, fallbackText)
              },
              translateColumnsSmartTable: (element, columnId, fallbackText) => {
                return translations.translateColumnsSmartTable(element, columnId, fallbackText)
              },
              translateOptionForSmartTable: (element, columnId, optionIndex, fallbackText) => {
                return translations.translateOptionForSmartTable(element, columnId, optionIndex, fallbackText)
              },
              translateRichText: (element, fallbackText) => {
                return translations.translateRichText(element, fallbackText)
              },
              translateMatrixOption: (element, optionId, fallbackText) => {
                return translations.translateMatrixOption(element, optionId, fallbackText)
              },
              translateMatrixQuestion: (element, questionId, fallbackText) => {
                return translations.translateMatrixQuestion(element, questionId, fallbackText)
              },
              addOrRemoveErrorInput,
              errorsWithDetail,
              formErrors: formErrors,
              handleHiddenContainersChange
            }}
          >
            <div className={`sf-toolbar-container flex justify-between px-5 py-3 print:p-0 ${toolBarTextColor}`} style={{ backgroundColor: dialogDefinition.toolbarColor}}>
              <div className="flex items-center">
                <span className={'sf-toolbar-saved-text print:hidden'}>
                  {saveMessage}
                </span>
              </div>

              <div className='flex items-center'>
                {dialogDefinition?.hasPowerOfAttorney &&
                  <button
                    onClick={onClickAttorneyButton}
                    className="mr-4 print:hidden w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                  >
                    Power of Attorney
                  </button>
                }
                {
                  translations.definitionLanguageOptions?.length > 0 &&
                  <div className="mr-4">
                    <Select
                      options={translations.definitionLanguageOptions}
                      onChange={translations.onLanguageChange}
                      selectedValue={translations.selectedLanguageOption?.id}
                    />
                  </div>
                }


                {!dialogDefinition?.disabledPdfGeneration &&
                  <FileText
                    onClick={onClickPreviewButton}
                    className="print:hidden cursor-pointer h-6 w-6"
                    title="Generate preview"
                  />
                }
              </div>

            </div>
            <form ref={formRef} onSubmit={onSave}>
              <Structures.Dialog data={dialogDefinition} isPdfMode={isPdf} isFormMode={true} />
            </form>

            <span id='ready-to-print' />

            <GeneratePdfDialog open={showGeneratePdf} setOpen={setShowGeneratePdf} />
            <SendingPdfToViewPointDialog open={showSendingPdfToViewPoint} setOpen={setShowSendingPdfToViewPoint} />
            <PowerOfAttorney open={showAttorney} setOpen={setShowAttorney} valuesKey={valuesKey} stepId={currentStepKey} data={atternyData} />

            {
              partiesToSignModalHelper.isOpen && (
                <PartiesToSign modalHelper={partiesToSignModalHelper} handleSendToSigning={handleSendToSigning} dialogDefinition={dialogDefinition}
                  canSignNow={canSignNow}
                  canDefinePartiesToSign={canDefinePartiesToSign}
                  availableSignAuthMethods={availableSignAuthMethods}
                />)
            }
            {/* <SignNowModal modalHelper={signNowModalHelper} onSignNow={handleSignNow} /> */}
            <SigningReadyModal modalHelper={signingReadyModalHelper}
              // availableSignAuthMethods={availableSignAuthMethods} 
              signUrl={signUrl} />
            <PreparingSignDocumentModal modalHelper={preparingSignDocumentModalHelper} />
          </FormContext.Provider>
      }
    </>
  )
}