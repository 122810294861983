import { useContext, useEffect, useRef, useState } from 'react'
import { DesignActionbarContext, ElementContext } from '../../contexts';
import useClickOutside from "../../hooks/useClickOutside";
import { useSelector } from 'react-redux';
import { DuplicateIcon, PlusSmIcon, ReplyIcon, TrashIcon } from '@heroicons/react/solid';
import { AdjustmentsIcon, ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { set } from 'lodash';
import { designContexts, sidebarRightTabs } from '../../utils/constants';

export default function ElementMenuContext({open, setMobileSideBarRightVisible, setMobileSideBarLeftVisible}) {
  const menu = useRef();
  const { showRightClickMenu, setShowRightClickMenu, rightClickMenuPosition, currentElement, designElementForContextMenu } = useContext(ElementContext) || {};
  const [processingUndo, setProcessingUndo] = useState(false)
  const [showDuplicateMenu, setShowDuplicateMenu] = useState(false)
  const [showRemoveMenu, setShowRemoveMenu] = useState(false)
  const [showPropertiesMenu, setShowPropertiesMenu] = useState(false)
  const dialogDefinitionHistory = useSelector((state) => state.dialogDefinitions.history)
  const designActionbarContext = useContext(DesignActionbarContext);

  const {
    setActiveTab,
    undo,
    handleRemoveFromType,
    handleDuplicateFromType,
    definitionManager,
    dialogDefinition,
    confirmRemoveModalHelper,
    setTypeToRemove
  } = designActionbarContext || {}

  useClickOutside(menu, () => setShowRightClickMenu(false));

  const close = () => {
    setShowRightClickMenu(false);
    setShowDuplicateMenu(false);
    setShowRemoveMenu(false);
  }

  const handleUndo = () => {
    setProcessingUndo(true)
    undo(enableUndo)
  }

  const enableUndo = () => {
    setProcessingUndo(false)
  }

  const handleAddClicked = (e) => {
    e.stopPropagation();
    setActiveTab(sidebarRightTabs.items);
    setMobileSideBarRightVisible(true)
    setMobileSideBarLeftVisible(false)
    close();
  }

  const removeFromType = (type) => {
    setTypeToRemove(type)
    confirmRemoveModalHelper.open();
    setShowRemoveMenu(false)
    close();
  }

  const getRightClickMenuPositionY = () => {
    let xPosition = rightClickMenuPosition?.y || 0

    if(xPosition > window.innerHeight - 280) {
      xPosition = window.innerHeight - 280
    }

    return xPosition;
  }
  
  const getRightClickMenuPositionX = () => {
    return rightClickMenuPosition?.x || 0
  }

  const handleShowPropertiesFromType = (type, e) => {
    switch (type) {
      case designContexts.row:
        designElementForContextMenu.selectRow(e, true)
        break;
      case designContexts.column:
        designElementForContextMenu.selectColumn(e, true)
        break;
      default:
      case designContexts.element:
        designElementForContextMenu.selectElement(e, true)
        break;
    }
  };

  return (
    <>
      {
        showRightClickMenu &&      
        <div
          ref={menu} 
          static className="absolute z-50 border border-gray-200 divide-y divide-gray-100 rounded-md bg-white shadow-lg"
          style={{top: `${getRightClickMenuPositionY()}px`, left: `${getRightClickMenuPositionX()}px`}}
        >
          <div className="flex flex-col w-56 py-1">
            <button
              onClick={handleAddClicked}
              className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
            >
              <PlusSmIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Add
            </button>
            <button
              onClick={handleUndo}
              disabled={processingUndo || dialogDefinitionHistory.length === 0}
              className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
            >
              <ReplyIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Undo
            </button>
          </div>

          <div className="flex flex-col w-56  py-1">
            <div className='flex justify-between hover:bg-gray-100 hover:text-gray-900'
              onMouseEnter={() => setShowPropertiesMenu(true)}
              onMouseLeave={() => setShowPropertiesMenu(false)}>
              <button
                onClick={() => setShowPropertiesMenu(true)}
                className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
              >
                <AdjustmentsIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Properties
              </button>
              <ChevronRightIcon
                className="mt-4 mr-2 h-3 w-3 text-black group-hover:text-gray-500"
                aria-hidden="true"
              />
              {
                showPropertiesMenu &&
                <div
                  static className="absolute z-50 pl-3"
                  style={{left: `218px`}}
                >
                  <div
                    static className="border border-gray-200 divide-y divide-gray-100 rounded-md bg-white shadow-lg"
                  >
                    <div className="flex flex-col w-40 py-1">
                      <button
                        onClick={(e) => handleShowPropertiesFromType('element', e)}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Element
                      </button>
                      <button
                        onClick={(e) => handleShowPropertiesFromType('column', e)}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Column
                      </button>
                      <button
                        onClick={(e) => handleShowPropertiesFromType('row', e)}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Row
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className='flex justify-between hover:bg-gray-100 hover:text-gray-900'
              onMouseEnter={() => setShowDuplicateMenu(true)}
              onMouseLeave={() => setShowDuplicateMenu(false)}>
              <button
                onClick={() => setShowDuplicateMenu(true)}
                className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
              >
                <DuplicateIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Duplicate
              </button>
              <ChevronRightIcon
                className="mt-4 mr-2 h-3 w-3 text-black group-hover:text-gray-500"
                aria-hidden="true"
              />
              {
                showDuplicateMenu &&
                <div
                  static className="absolute z-50 pl-3"
                  style={{left: `218px`}}
                >
                  <div
                    static className="border border-gray-200 divide-y divide-gray-100 rounded-md bg-white shadow-lg"
                  >
                    <div className="flex flex-col w-40 py-1">
                      <button
                        onClick={() => handleDuplicateFromType('element', currentElement.id)}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Element
                      </button>
                      <button
                        onClick={() => handleDuplicateFromType('column', currentElement.id)}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Column
                      </button>
                      <button
                        onClick={() => handleDuplicateFromType('row', currentElement.id)}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Row
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className='flex justify-between hover:bg-gray-100 hover:text-gray-900'
              onMouseEnter={() => setShowRemoveMenu(true)}
              onMouseLeave={() => setShowRemoveMenu(false)}>
              <button
                onClick={() => setShowDuplicateMenu(true)}
                className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
              >
                <TrashIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Remove
              </button>
              <ChevronRightIcon
                className="mt-4 mr-2 h-3 w-3 text-black group-hover:text-gray-500"
                aria-hidden="true"
              />
              {
                showRemoveMenu &&
                <div
                  static className="absolute z-50 pl-3"
                  style={{left: `218px`}}
                >
                  <div
                    static className="border border-gray-200 divide-y divide-gray-100 rounded-md bg-white shadow-lg"
                  >
                    <div className="flex flex-col w-40 py-1">
                      <button
                        onClick={() => removeFromType('element')}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Element
                      </button>
                      <button
                        onClick={() => removeFromType('column')}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Column
                      </button>
                      <button
                        onClick={() => removeFromType('row')}
                        className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
                      >
                        Row
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>

          </div>
          
        </div>
      }
    </>
  )
}