import { useEffect, useState, useContext } from 'react'
import { useElementDesign } from "../useElementDesign"
import './styles.scss'
import Icon from "../../icons"
import { FormContext, DialogModes } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import InlineEditing from '../../components/InlineEditing'
import { getDialogValueKey } from '../../utils/features'
import { useDynamicStringReplacement } from '../../hooks/useDynamicStringReplacement'

export default function TextLine({ data, ...props }) {
  const formContext = useContext(FormContext);
  const [textToShow, setTextToShow] = useState("");
  const elementDesign = useElementDesign(data.id, data);
  const elementStyle = useElementStyle(data);
  const { isEditMode } = useContext(DialogModes);
  const isLocked = elementDesign.isLocked();

  const classPrefix = "textLine";
  const { borderWidth, borderColor } = elementStyle.borderAndWidthStyle();
  const { color, fontSize, width } = elementStyle.textStyle(data?.text);
  const roundedCorners = data?.roundedCorners ? 'rounded-md' : '';

  const textLineStyle = {
    fontSize: fontSize,
    color: color,
    lineHeight: 1.4,
    width: width,
    overflowY: 'hidden',
    borderWidth: borderWidth,
    borderColor: borderColor
  }

  const iconStyle = {
    fontSize: fontSize,
    color: color
  }

  const textValue = formContext?.inputValues[getDialogValueKey(data)]?.value || data.value

  useEffect(() => {
    setTextToShow(elementDesign.translateTerm(textValue))
  }, [data, elementDesign, textValue])

  let translatedText = elementDesign.translateTerm(textValue)

  const textWithReusableValues = useDynamicStringReplacement(translatedText, formContext?.reusableProperties, formContext?.dialogValues?.dataTableValues, true, formContext?.elements);

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div className={`mt-0 ${isEditMode && !isLocked ? 'flex' : ''}`}>
        {isEditMode && !isLocked && <Icon style={iconStyle} type={data?.leadingIcon} />}
        <InlineEditing
          initData={data}
          style={textLineStyle}
          classes={`sf-text-line element ${classPrefix} ${elementStyle.prefixClassName(classPrefix)} ${roundedCorners}`}
          name='value'
          value={textToShow}
          id={`label-${data.id}`}
          autoSize
          useHighlightWithinTextarea
        >
          <p
            className={`sf-text-line element p-0 ${classPrefix} ${elementStyle.prefixClassName(classPrefix)} ${roundedCorners}`}
            style={textLineStyle}
          >
            <Icon style={iconStyle} type={data?.leadingIcon} />
            <span 
              className='sf-text-line whitespace-pre-line break-words'
              style={{whiteSpace: 'pre-wrap'}}
            >
              {textWithReusableValues}
              {elementDesign.translateHelpText(data)}
            </span>
          </p>
        </InlineEditing>
      </div>
    </InlineActionbarElementWrapper>
  )
}
